// Generated by CoffeeScript 1.10.0
(function() {
  (function(p, $, d, w) {
    var MealPlannerMobile;
    MealPlannerMobile = (function() {
      var self;

      self = false;

      function MealPlannerMobile() {
        self = this;
        this.isMobileDevice = /Android|Kindle|Silk|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
        self.settings = {
          headerTag: 'h2',
          bodyTag: 'section',
          contentContainerTag: 'div',
          actionContainerTag: 'div',
          stepsContainerTag: 'div',
          cssClass: 'wizard',
          loadingTemplate: '<span class="spinner"></span> #text#',
          autoFocus: true,
          enableAllSteps: false,
          enableKeyNavigation: false,
          enablePagination: false,
          suppressPaginationOnFocus: true,
          enableContentCache: true,
          enableCancelButton: true,
          enableFinishButton: true,
          preloadContent: false,
          showFinishButtonAlways: false,
          forceMoveForward: false,
          saveState: false,
          startIndex: 0,
          transitionEffect: 'slideLeft',
          transitionEffectSpeed: 200,
          onStepChanged: function() {
            return self.onStepChangedFunctions();
          },
          labels: {
            cancel: 'Cancel',
            current: 'current step:',
            pagination: 'Pagination',
            finish: 'Finish',
            next: 'Next',
            previous: 'Previous',
            loading: 'Loading ...'
          }
        };
      }

      MealPlannerMobile.prototype.isRunnable = function() {
        return $('#meal-planner-table-mobile').length > 0 && $(w).width() < 768;
      };

      MealPlannerMobile.prototype.ready = function() {
        self = this;
        self.mealTable = $('#meal-planner-table-mobile');
        self.wizard = $('#ps-mobile');
        return $(w).load(function() {
          if (self.isRunnable()) {
            self.bindings();
            return self.load();
          }
        });
      };

      MealPlannerMobile.prototype.load = function() {
        var currentStep, currentStepIndex;
        self.wizard.steps(self.settings);
        currentStep = self.wizard.steps('getCurrentStep');
        $('#ps-mobile .status-bar .section-name').text(currentStep.title);
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        $('#ps-mobile-p-' + currentStepIndex).height('100%');
        if (currentStepIndex === 0) {
          $('.status-bar .back-step-button').hide();
        }
        w.setTimeout(function() {
          self.hideLoading();
        }, 1500);
      };

      MealPlannerMobile.prototype.showLoading = function() {
        return $('#meal-planner-table-mobile .loading').show();
      };

      MealPlannerMobile.prototype.hideLoading = function() {
        return $('#meal-planner-table-mobile .loading').hide();
      };

      MealPlannerMobile.prototype.onStepChangedFunctions = function() {
        var currentStep, currentStepIndex;
        $('html,body').stop(false, true).animate({
          scrollTop: 0
        }, 'fast');
        currentStep = self.wizard.steps('getCurrentStep');
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        $('#ps-mobile-p-' + currentStepIndex).height('100%');
        $('.status-bar .back-step-button').show();
        $('#ps-mobile .status-bar .center-container').removeClass('full-width');
        if (currentStepIndex !== 1 && currentStepIndex !== 3) {
          $('#ps-mobile .status-bar .section-name').text(currentStep.title);
        }
        if (currentStepIndex === 0) {
          $('.wrap-tutorial').show();
          $('.wrap-day-printer').show();
          $('.status-bar .back-step-button').hide();
          $('#ps-mobile .status-bar .center-container').addClass('full-width');
          $('#ps-mobile .status-bar .meal-day-name').text('');
          $('header').show();
          $('#menu-bar').show();
          return $('.form-download-pdf').show();
        } else {
          $('.wrap-tutorial').hide();
          $('.wrap-day-printer').hide();
          $('.form-download-pdf').hide();
          $('header').hide();
          return $('#menu-bar').hide();
        }
      };

      MealPlannerMobile.prototype.viewDish = function(element) {
        var dataSend, dishElement, dishId, mealId, type, weekday;
        dishElement = $(element).parents('.dish-container');
        self.prepareDataForSave($(dishElement));
        dishId = dishElement.attr('data-dish-id');
        type = dishElement.attr('data-dish-type');
        weekday = dishElement.attr('data-day-id');
        mealId = dishElement.attr('data-meal-id');
        self.showLoading();
        dataSend = {
          'action': 'get_dish_details',
          'is_ajax': 1,
          'dish_id': dishId,
          'weekday': weekday,
          'meal_id': mealId,
          'type': type
        };
        return $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.dish_id) {
              if (data.dish_template) {
                $('#single-dish-edit').html(data.dish_template);
              }
              $('.status-bar .meal-day-name').text(data.day_name + ' - ' + data.meal_name);
              self.hideLoading();
              self.nextStep();
            }
          }
        });
      };

      MealPlannerMobile.prototype.viewRicette = function(dishId, type) {
        var dataSend;
        self.showLoading();
        self.wizard.attr('data-step-bar-title', $('#ps-mobile .status-bar .section-name').text());
        dataSend = {
          'action': 'get_dish_recipes',
          'is_ajax': 1,
          'dish_id': dishId,
          'type': type
        };
        $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            var currentStepIndex;
            if (data.dish_id) {
              currentStepIndex = self.wizard.steps('getCurrentIndex');
              self.wizard.steps('insert', currentStepIndex + 1, {
                title: data.dish_name,
                content: data.recipes_template
              });
              self.hideLoading();
              self.nextStep();
              self.wizard.attr('data-step-remove', currentStepIndex + 1);
            }
          }
        });
      };

      MealPlannerMobile.prototype.editDish = function(element) {
        var dataSend, dishElement, dishId, mealId, parentWrap, type, weekday;
        dishElement = $(element);
        dishId = dishElement.attr('data-dish-id');
        type = dishElement.attr('data-dish-type');
        weekday = $('input#save-day-id').val();
        mealId = $('input#save-meal-id').val();
        self.showLoading();
        parentWrap = dishElement.parents('.single-list-type');
        dataSend = {
          'action': 'get_dish_details',
          'is_ajax': 1,
          'dish_id': dishId,
          'weekday': weekday,
          'meal_id': mealId,
          'confirm_dish': true,
          'type': type
        };
        return $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            $('#ps-mobile .status-bar .section-name').text($('.name-title-group', parentWrap).text());
            if (data.dish_id) {
              if (data.dish_template) {
                $('#single-dish-edit-confirm').html(data.dish_template);
              }
              $('.status-bar .meal-day-name').text(data.day_name + ' - ' + data.meal_name);
              self.hideLoading();
              self.nextStep();
            }
          }
        });
      };

      MealPlannerMobile.prototype.saveMeal = function(dishId, type) {
        var cell, dataSend, mealId, weekday;
        weekday = $('input#save-day-id').val();
        mealId = $('input#save-meal-id').val();
        cell = $('.meals-container .meal-container[data-day-id="' + weekday + '"][data-meal-id="' + mealId + '"]');
        dataSend = {
          'action': 'save_meal_plan_mobile',
          'is_ajax': 1,
          'dish_id': dishId,
          'type': type,
          'weekday': weekday,
          'meal_id': mealId,
          'user_id': $('#meal-planner-table-mobile').attr("data-user-id")
        };
        self.showLoading();
        return $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.mealplan_id) {
              $('.dish-container', cell).attr("data-dish-id", dataSend.dish_id);
              $('.dish-container', cell).attr("data-dish-type", dataSend.type);
              $('.dish-container', cell).attr("data-mealplan-id", data.mealplan_id);
              $('.text-title .dish-name', cell).text('- ' + data.dish_name);
            }
            if (data.dish_template) {
              $('.dish-wrapper', cell).html(data.dish_template);
            }
            self.hideLoading();
            self.restart();
          }
        });
      };

      MealPlannerMobile.prototype.saveException = function() {
        var cell, dataSend, mealId, weekday;
        if ($('#meal-planner-table-mobile .dish-container.exception').length === 2) {
          $('.container-set-exception .error-message').show();
          w.setTimeout(function() {
            $('.container-set-exception .error-message').hide();
          }, 3000);
          return;
        }
        weekday = $('input#save-day-id').val();
        mealId = $('input#save-meal-id').val();
        cell = $('.meals-container .meal-container[data-day-id="' + weekday + '"][data-meal-id="' + mealId + '"]');
        self.showLoading();
        dataSend = {
          'action': 'save_meal_plan_exception',
          'is_ajax': 1,
          'weekday': weekday,
          'dish_id': null,
          'type': 'exception',
          'meal_id': mealId,
          'user_id': $('#meal-planner-table-mobile').attr("data-user-id")
        };
        return $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.mealplan_id) {
              $('.dish-container', cell).attr("data-mealplan-id", data.mealplan_id);
              $('.dish-container', cell).attr('data-dish-type', dataSend.type);
              $('.dish-wrapper', cell).html(data.exception_template);
              $('.text-title .dish-name', cell).text('- Eccezione');
            }
            $('.dish-container', cell).removeClass('dish-redazione');
            $('.dish-container', cell).addClass('exception');
            self.hideLoading();
            self.restart();
            $('html,body').delay(1000).stop(false, true).animate({
              scrollTop: 0
            }, 'slow');
          }
        });
      };

      MealPlannerMobile.prototype.restart = function() {
        $('#ps-mobile-t-0').trigger('click');
        return self.clearDataForSave();
      };

      MealPlannerMobile.prototype.removeException = function(elem) {
        var cell, dataSend, mealplanId;
        self.showLoading();
        cell = $(elem).parents('.meal-container');
        mealplanId = $('.dish-container', cell).attr("data-mealplan-id");
        dataSend = {
          'mealplan_id': mealplanId,
          'action': 'delete_dish_mealplan_mobile',
          'is_ajax': 1,
          'weekday': $('.dish-container', cell).attr('data-day-id'),
          'meal_id': $('.dish-container', cell).attr('data-meal-id')
        };
        $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            $('.dish-container', cell).attr("data-dish-id", dataSend.dish_id);
            $('.dish-container', cell).attr("data-dish-type", 'default');
            $('.dish-container', cell).removeClass('exception');
            $('.text-title .dish-name', cell).text('- ' + data.dish_name);
            if (data.dish_template) {
              $('.dish-wrapper', cell).html(data.dish_template);
            }
            self.hideLoading();
          }
        });
        return false;
      };

      MealPlannerMobile.prototype.prepareDataForSave = function(elem) {
        var dayId, mealId, mealplanId;
        mealId = elem.attr('data-meal-id');
        dayId = elem.attr('data-day-id');
        mealplanId = elem.attr('data-mealplan-id');
        $('input#save-meal-id').val(mealId);
        $('input#save-day-id').val(dayId);
        return $('input#save-mealplan-id').val(mealplanId);
      };

      MealPlannerMobile.prototype.clearDataForSave = function() {
        $('input#save-meal-id').val('');
        $('input#save-day-id').val('');
        return $('input#save-mealplan-id').val('');
      };

      MealPlannerMobile.prototype.removeStep = function() {
        var currentStep, stepToRemove;
        stepToRemove = self.wizard.attr('data-step-remove');
        if (stepToRemove) {
          self.wizard.steps('remove', stepToRemove);
          self.wizard.attr('data-step-remove', '');
        }
        currentStep = self.wizard.steps('getCurrentStep');
        return $('#ps-mobile .status-bar .section-name').text(currentStep.title);
      };

      MealPlannerMobile.prototype.nextStep = function() {
        self.showLoading();
        self.wizard.steps('next');
        return self.hideLoading();
      };

      MealPlannerMobile.prototype.prevStep = function() {
        var prevTitle;
        self.wizard.steps('previous');
        self.removeStep();
        prevTitle = self.wizard.attr('data-step-bar-title');
        if (prevTitle) {
          return $('#ps-mobile .status-bar .section-name').text(prevTitle);
        }
      };

      MealPlannerMobile.prototype.openLinkList = function(arr) {
        return $.each(arr, function(key, link) {
          if (link !== '') {
            return w.open(link);
          }
        });
      };

      MealPlannerMobile.prototype.bindings = function() {
        $('#ps-mobile').on("click", ".meal-container .meal-title", function(event) {
          var parent;
          parent = $(this).parent();
          $('.meal-details', parent).stop(false, true).slideToggle();
          return parent.toggleClass('active');
        });
        $('#ps-mobile').on("click", ".dishes-list-type .single-list-type-title", function(event) {
          var parent;
          parent = $(this).parent();
          if (!parent.hasClass('active')) {
            $('#ps-mobile .accordion-wrap.active .accordion-content').stop(false, true).slideUp();
            $('#ps-mobile .accordion-wrap.active').removeClass('active');
          }
          $('.accordion-content', parent).stop(false, true).slideToggle();
          return parent.toggleClass('active');
        });
        $('.wrap-day-printer').on("click", ".day-buttons .day-button", function(event) {
          $('#form-download-pdf-mobile input[name="day_id"]').val($(this).attr('data-day-id'));
          return $('#form-download-pdf-mobile').submit();
        });
      };

      return MealPlannerMobile;

    })();
    w.mealPlannerMobile = new MealPlannerMobile();
    mealPlannerMobile.ready();
  })($, jQuery, document, window);

}).call(this);
